import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";

const PlaygroundPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout headerTitle={post.frontmatter.title}>
      <Helmet titleTemplate="ScriptDrop | %s">
        <title>{`${post.frontmatter.title}`}</title>
      </Helmet>

      <article>
        <HTMLContent
          className="prose lg:prose-lg max-w-none"
          content={post.html}
        />
      </article>
    </Layout>
  );
};

export default PlaygroundPage;

export const playgroundPageQuery = graphql`
  query PlayGroundPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
